import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Cart, LineItem } from "../Cart/LineItem";
import { Product } from "../common/product";
import { CartContext } from "../Cart/CartContext";
import { useProducts } from "../Networking/useProducts";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ServerBaseLineItem } from "../common/order";
import { CartRecapBottomBar } from "../Cart/CartRecapBottomBar";
import { CartRecapSider } from "../Cart/CartRecapSider";
import styles from "./styles.module.css";
import Constants from "../common/constants";
import { Button, Col, Form, notification, Row, Spin, Layout } from "antd";
import useUATracker from "../Networking/Metrics/useUATracker";
import {
  FillBoxCtaSelected,
  MembershipToggleClicked,
  OrderCheckout,
  OrderConfirmCtaSelected,
  SubscriptionProfileEdited,
  SubscriptionProfileSelected,
  TastingBoxSelected,
} from "../Networking/Metrics/UserActions/Shop";
import { ShippingInfo } from "../common/shippingInfo";
import useApiClient from "../Networking/useApiClient";
import {
  OrderProductAdded,
  OrderProductRemoved,
} from "../Networking/Metrics/UserActions/Products";
import { BoxConfig, useBoxConfig } from "../Hooks/useBoxConfig";
import { useMe } from "../AppSetup/MeContext";
import ShopHeader from "./ShopHeader";
import ShopHeaderSteps from "./ShopHeaderSteps";
import {
  Instalments,
  PurchaseLimitation,
  Subscription,
  useSettings,
} from "../Networking/useSettings";
import { useDiscount } from "../Hooks/useDiscount";
import { ShopState } from "./ShopState";
import OfferModal from "../ContactConsentModal/OfferModal";
import OnboardingModal from "../ShopOnboarding/OnboardingModal";
import { Me } from "../common/me";
import { invalidate } from "../Networking/common";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { useSubscriptions } from "../Hooks/useSubscriptions";
import EventEmitter from "events";
import { LeftOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const mapServerLineItems = (
  lineItems: ServerBaseLineItem[],
  productsMap: { [key: string]: Product },
): LineItem[] => {
  if (!lineItems) {
    return [];
  }
  return lineItems
    .map((lineItem) => {
      const product = productsMap[lineItem.variantId];
      if (!product) {
        console.error(`Missing product ${lineItem.variantId} in products list`);
        return null;
      }
      return {
        product: product,
        quantity: lineItem.quantity,
      };
    })
    .filter((el) => el !== null) as LineItem[];
};

export const getInitialLineItems = (
  me: Me,
  products: Product[],
): LineItem[] => {
  const productsMap = products.reduce(
    (map, product) => {
      map[product.id] = product;
      return map;
    },
    {} as { [key: string]: Product },
  );
  let data = localStorage.getItem(Constants.STORAGE_LINE_ITEMS_KEY);
  const storedLineItems: LineItem[] = data ? JSON.parse(data) : [];
  if (me.subscriptionProfile) {
    const subLineItems = mapServerLineItems(
      me.subscriptionProfile.cartItems.map((el) => {
        return {
          variantId: el.productId,
          quantity: el.quantity,
        } as ServerBaseLineItem;
      }),
      productsMap,
    );
    return subLineItems;
  }
  if (me.order) {
    const orderLineItems = mapServerLineItems(me.order.lineItems, productsMap);
    if (orderLineItems.length > 0) {
      return orderLineItems;
    }
  }
  return storedLineItems;
};

const saveLineItems = (lineItems: LineItem[]): void => {
  if (lineItems.length === 0) {
    localStorage.removeItem(Constants.STORAGE_LINE_ITEMS_KEY);
  } else {
    localStorage.setItem(
      Constants.STORAGE_LINE_ITEMS_KEY,
      JSON.stringify(lineItems),
    );
  }
};

export const Shop = () => {
  const { t } = useTranslation();
  const today = useMemo(() => dayjs(), []);
  const yesterday = useMemo(() => today.subtract(1, "day"), [today]);
  const { products, productsMap } = useProducts();
  const me = useMe();
  const [lineItems, _setLineItems] = useState<LineItem[]>(
    getInitialLineItems(me, products),
  );
  const location = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const settings = useSettings();

  const { lg } = useBreakpoint(true);

  const [disablePrimaryButton, setDisablePrimaryButton] = useState(false);

  const [shippingInfoForm] = Form.useForm();

  const [shippingDate, setShippingDate] = useState<Dayjs | undefined>();
  const [shippingNotes, setShippingNotes] = useState<string | undefined>();
  const [shippingTimeSlot, setShippingTimeSlot] = useState<
    string | undefined
  >();

  const [editingSubscription, setEditingSubscription] = useState(false);

  const [waitingListPriceId, setWaitingListPriceId] = useState<
    string | undefined
  >();
  const [waitingListLength, setWaitingListLength] = useState<
    number | undefined
  >();

  const {
    getCheckoutUrl,
    getSubscriptionCheckoutUrl,
    getBestSellers,
    createCheckoutSession,
  } = useApiClient();
  const { trackUserAction, sendRecentEvents } = useUATracker();
  const _boxConfig = useBoxConfig();
  const [currentStateIndex, setCurrentStateIndex] = useState(0);

  const [onboardingOpen_, setOnboardingOpen] = useState(true);

  const dismissOnboarding = () => {
    localStorage.setItem(Constants.STORAGE_ONBOARDING_KEY, "true");
    setOnboardingOpen(false);
  };

  const onboardingOpen = useMemo(
    () =>
      !!(
        onboardingOpen_ &&
        settings.showSubscriptionOnboarding &&
        !me.subscriptionProfile &&
        !localStorage.getItem(Constants.STORAGE_ONBOARDING_KEY)
      ),
    [
      onboardingOpen_,
      me.subscriptionProfile,
      settings.showSubscriptionOnboarding,
    ],
  );
  const [bestSellers, setBestSellers] = useState<Product[] | undefined>();
  const discount = useDiscount();

  const [headerIndexCount, setHeaderIndexCount] = useState(0);

  const userSub =
    me?.subscriptionProfile && settings.subsEnabled
      ? settings.subscriptions.find(
          (el) => el.sku === me.subscriptionProfile.subscriptionSku,
        )
      : undefined;

  const [selectedSub, setSelectedSub] = useState<Subscription | undefined>(
    userSub,
  );

  const subsMap = useSubscriptions();

  const defaultSubs = useMemo(
    () =>
      settings.defaultSubscriptionPeriodInWeeks && subsMap
        ? subsMap[settings.defaultSubscriptionPeriodInWeeks]
        : undefined,
    [settings.defaultSubscriptionPeriodInWeeks, subsMap],
  );

  useEffect(() => {
    if (!selectedSub && !!userSub && !editingSubscription) {
      setSelectedSub(userSub);
    }
  }, [selectedSub, userSub, setSelectedSub, editingSubscription]);

  const boxConfig = useMemo(() => {
    if (defaultSubs && !userSub) {
      return _boxConfig
        .filter((el) => Object.keys(defaultSubs).includes(String(el.items)))
        .map((box) => {
          return {
            ...box,
            price: (defaultSubs[box.items].price / 100).toFixed(2).toString(),
          };
        });
    }
    if (!selectedSub) {
      return _boxConfig;
    }
    return _boxConfig.filter((el) => el.items === selectedSub.boxSize);
  }, [_boxConfig, selectedSub, defaultSubs, userSub]);

  const [membershipInCart, _setMembershipInCart] = useState<string | undefined>(
    !me.isMember &&
      (!settings.freemiumMode || settings.membershipInCartByDefault)
      ? settings.membership?.sku
      : undefined,
  );

  useEffect(() => {
    async function fetchBestSellers() {
      const bestSellers = await getBestSellers(
        boxConfig[0].items,
        settings.productsCollectionId,
      );
      if (bestSellers) {
        const bestSellerProducts = products.filter(
          (product) => bestSellers.indexOf(product.sku) !== -1,
        );
        setBestSellers(bestSellerProducts);
      }
    }

    void fetchBestSellers();
  }, [boxConfig, getBestSellers, products, settings.productsCollectionId]);

  const setMembershipInCart = (value: string | undefined) => {
    _setMembershipInCart(me.isMember ? undefined : value);
  };

  const validateLineItems = useCallback(
    (lineItems: LineItem[]) => {
      return lineItems
        .map((li) => {
          const product = productsMap[li.product.id];
          if (!product || product.inventoryQuantity < li.quantity) {
            return null;
          }
          return {
            product: product,
            quantity: li.quantity,
          };
        })
        .filter((el) => el !== null) as LineItem[];
    },
    [productsMap],
  );

  const setLineItems = useCallback(
    (lineItems: LineItem[]) => {
      _setLineItems(validateLineItems(lineItems));
    },
    [validateLineItems],
  );

  // Save line items to local storage when they change
  useEffect(() => {
    const validatedLineItems = validateLineItems(lineItems);
    if (
      validatedLineItems.length !== lineItems.length ||
      validatedLineItems.some(
        (li, index) => li.quantity !== lineItems[index].quantity,
      )
    ) {
      _setLineItems(validatedLineItems);
    }
    saveLineItems(validatedLineItems);
  }, [lineItems, validateLineItems]);

  const addProduct = (product: Product) => {
    trackUserAction(new OrderProductAdded({ productName: product.title }));
    if (totalItems >= Math.max(...boxConfig.map((el) => el.items))) {
      return;
    }
    const lineItemIndex = lineItems!.findIndex(
      (li) => li.product.id === product.id,
    );
    if (lineItemIndex < 0) {
      setLineItems([...lineItems!, { product: product, quantity: 1 }]);
    } else {
      setLineItems([
        ...lineItems.slice(0, lineItemIndex),
        {
          product: product,
          quantity: lineItems[lineItemIndex].quantity + 1,
        },
        ...lineItems.slice(lineItemIndex + 1),
      ]);
    }
  };

  const removeProduct = (product: Product) => {
    trackUserAction(new OrderProductRemoved({ productName: product.title }));
    const lineItemIndex = lineItems!.findIndex(
      (li) => li.product.id === product.id,
    );
    if (lineItemIndex < 0) {
      return;
    } else {
      if (lineItems[lineItemIndex].quantity === 1) {
        setLineItems(lineItems!.filter((li) => li.product.id !== product.id));
      } else {
        setLineItems([
          ...lineItems.slice(0, lineItemIndex),
          {
            product: product,
            quantity: lineItems[lineItemIndex].quantity - 1,
          },
          ...lineItems.slice(lineItemIndex + 1),
        ]);
      }
    }
  };

  const totalItems = lineItems
    ? lineItems.reduce((tot, li) => tot + li.quantity, 0)
    : 0;

  const [recapOpen, setRecapOpen] = useState(false);

  const currentBox = boxConfig.find((el) => el.items === totalItems);
  let currentDisplayedBox_ = boxConfig.find((el) => el.items >= totalItems);

  if (!currentDisplayedBox_) {
    currentDisplayedBox_ = boxConfig[0];
  }
  const currentDisplayedBox = currentDisplayedBox_ as BoxConfig;

  useEffect(() => {
    if (defaultSubs && !userSub) {
      setSelectedSub(defaultSubs[currentDisplayedBox.items]);
    }
  }, [currentDisplayedBox, defaultSubs, userSub]);

  let instalments: Instalments | undefined;
  if (!me.latestOrder && membershipInCart) {
    instalments = currentDisplayedBox?.instalments;
  }

  const autofillCart = useCallback(async () => {
    setLoading(true);
    if (!bestSellers) {
      return;
    }

    setLineItems(bestSellers.map((product) => ({ product, quantity: 1 })));
    trackUserAction(
      new FillBoxCtaSelected({ boxSize: currentDisplayedBox.items }),
    );
    !settings.improvedShop && setRecapOpen(true);
    setLoading(false);
  }, [
    bestSellers,
    currentDisplayedBox,
    setLineItems,
    trackUserAction,
    settings.improvedShop,
  ]);

  let currentBoxPrice = currentDisplayedBox.price;
  if (!me.isMember && settings.freemiumMode && !membershipInCart) {
    currentBoxPrice = currentDisplayedBox.nonMembershipPrice!;
  }

  if (instalments) {
    currentBoxPrice = (instalments.immediatePriceInCents / 100).toFixed(2);
  }

  let currentTotal = parseFloat(currentBoxPrice);
  if (
    membershipInCart &&
    settings.membership &&
    !settings.membership?.freeTrialPeriod
  ) {
    currentTotal += parseFloat(settings.membership.price);
  }
  if (settings.subsEnabled) {
    currentTotal =
      (selectedSub?.price || 1) / 100 || parseFloat(currentBoxPrice);
  }

  if (instalments) {
    currentTotal = instalments.immediatePriceInCents / 100;
  }

  const totalWithoutMembership = parseFloat(
    currentDisplayedBox.nonMembershipPrice || currentDisplayedBox.price,
  );
  const totalWithMembership = !settings.membership
    ? totalWithoutMembership
    : parseFloat(currentDisplayedBox.price) +
      (!!settings.membership.freeTrialPeriod
        ? 0
        : parseFloat(settings.membership.price));
  const saving = totalWithoutMembership - totalWithMembership;

  const { Content } = Layout;

  const shopStates: ShopState[] = useMemo(() => {
    let shopStates: ShopState[] = [
      {
        url: "/shop/edit",
        editable: true,
        primaryButtonLabel:
          totalItems === 0
            ? t("Riempi con i best-seller")
            : settings.freemiumMode && !me.isMember
              ? settings.subsEnabled
                ? t("Continua")
                : membershipInCart
                  ? t("Continua con Membership")
                  : t("Continua senza Membership")
              : t("Continua"),
        primaryButtonTopText:
          totalItems === 0 ? t("Non sai che piatti scegliere? 🤔") : undefined,
        onConfirm:
          totalItems !== 0
            ? async () => {
                trackUserAction(new OrderConfirmCtaSelected());
                navigate(shopStates[currentStateIndex + 1].url);
              }
            : autofillCart,
        disablePrimaryButton: () =>
          loading || (totalItems !== 0 && !currentBox),
        increasesHeaderIndex: true,
        hideProgress:
          settings.subsEnabled &&
          !settings.defaultSubscriptionPeriodInWeeks &&
          !lg,
        showTotalInCTA: !me.subscriptionProfile,
        hideCart: lg,
        showCartSider: true,
      },
      {
        url: "/shop/shipping",
        primaryButtonLabel: t("Scegli data di consegna"),
        onConfirm: async () => {
          navigate(shopStates[currentStateIndex + 1].url);
        },
        disablePrimaryButton: () => !me?.shippingInfo.address?.address1,
        increasesHeaderIndex: true,
        hideProgress: settings.subsEnabled,
        showTotalInCTA: !me.subscriptionProfile,
        hideCart: lg,
        showCartSider: true,
      },
      {
        url: "/shop/shipping/select-date",
        increasesHeaderIndex: true,
        onConfirm: async () => {
          setLoading(true);
          try {
            const shippingInfo: ShippingInfo =
              (await shippingInfoForm.validateFields()) as ShippingInfo;
            setShippingDate(shippingInfo.date);
            setShippingNotes(shippingInfo.notes);
            if (shippingInfo && lineItems) {
              const draftOrderIdentical =
                !!me?.order &&
                me?.order.lineItems.every((li) => {
                  const purchasedLi = lineItems.find(
                    (el) => el.product.id === li.variantId,
                  );
                  return purchasedLi && purchasedLi.quantity === li.quantity;
                });
              // Track the checkout click
              trackUserAction(
                new OrderCheckout({
                  deliveryDate: shippingInfo.date,
                  deliveryTime: shippingInfo.timeSlot,
                  draftOrderExists: !!me?.order,
                  draftOrderIdentical: draftOrderIdentical,
                  invoiceData: me?.invoiceData,
                }),
              );
              // Before redirecting, send the recent events
              await sendRecentEvents();
              if (settings.subsEnabled) {
                const checkoutResponse = await getSubscriptionCheckoutUrl(
                  lineItems,
                  shippingInfo,
                  selectedSub ? selectedSub.sku : membershipInCart,
                  discount?.code,
                );

                await trackUserAction(
                  new SubscriptionProfileEdited({
                    subscriptionIsActive: !!me.subscriptionProfile,
                    subscriptionBoxSize: selectedSub?.boxSize || 0,
                    subscriptionBoxFrequency:
                      selectedSub?.period.count.toString() || "0",
                    subscriptionNextBoxDeliveryDate: shippingInfo.date,
                  }),
                );

                if (checkoutResponse?.status === "updated") {
                  notification.success({
                    message: "Abbonamento aggiornato",
                    description:
                      "Il tuo abbonamento è stato aggiornato con successo!",
                  });
                  await invalidate("/me");
                  navigate("/shop/subscription-recap");
                } else {
                  if (checkoutResponse?.checkoutUrl) {
                    window.location.assign(checkoutResponse.checkoutUrl);
                  }
                }
              } else {
                const checkoutResponse = await getCheckoutUrl({
                  lineItems,
                  shippingInfo,
                  membership: selectedSub ? selectedSub.sku : membershipInCart,
                  discountCode: discount?.code,
                  instalmentSku: instalments
                    ? instalments.membershipSku
                    : undefined,
                });
                if (checkoutResponse && checkoutResponse.checkoutUrl) {
                  window.location.assign(checkoutResponse.checkoutUrl);
                }
              }
            }
          } finally {
            setLoading(false);
          }
        },
        disablePrimaryButton: () => !shippingDate,
        primaryButtonLabel: !me.subscriptionProfile
          ? t("Vai al pagamento")
          : t("Conferma modifiche"),
        hideProgress: settings.subsEnabled,
        showTotalInCTA: !me.subscriptionProfile,
        hideCart: lg,
        showCartSider: true,
      },
    ];

    if (settings.stripePayment) {
      if (!me.subscriptionProfile) {
        shopStates[shopStates.length - 1].onConfirm = undefined;
      } else {
        shopStates[shopStates.length - 1].onConfirm = async () => {
          if (!shippingDate) return;
          setLoading(true);
          const shippingInfo: ShippingInfo = {
            firstName: me.firstName,
            lastName: me.lastName,
            date: shippingDate,
            notes: shippingNotes || "",
          };
          createCheckoutSession({
            lineItems,
            shippingInfo,
            membership: membershipInCart,
            selectedSub,
            paymentConfigurationId: settings.stripePaymentConfigurationId,
          }).then((response) => {
            if (response.action === "completed") {
              navigate("/");
            }
            setLoading(false);
          });
        };
      }
      shopStates.push({
        url: "/shop/stripe/checkout",
        increasesHeaderIndex: false,
        onConfirm: async () => {
          // Do nothing, the stripe component will handle the payment
        },
        primaryButtonLabel: t("Completa l'ordine"),
        secondaryButtonLabel: t("Cancella"),
        hideFooter: true,
        showHeaderButton: true,
        showCartSider: true,
      });
      shopStates.push({
        url: "/shop/stripe/checkout/succeeded",
        increasesHeaderIndex: false,
        onConfirm: async () => {
          // Do nothing, the stripe component will handle the payment
        },
        primaryButtonLabel: t("Completa l'ordine"),
        secondaryButtonLabel: t("Cancella"),
        hideFooter: true,
      });
    }

    if (settings.unlimitedEnabled && !me.isUnlimitedMember) {
      shopStates = [
        {
          url: "/shop/unlimited-paywall",
          primaryButtonLabel: t("Acquista Unlimited"),
          increasesHeaderIndex: true,
          hideProgress: true,
          showTotalInCTA: false,
          disablePrimaryButton: () => false,
          hideCart: true,
          hideFooter: lg,
          hideHeader: lg,
          primaryButtonTopText:
            waitingListPriceId &&
            t(
              "A causa dell’elevata domanda, per garantirvi un migliore servizio, Humamy è momentaneamente ad accesso limitato. 🙏",
            ),
        },
        {
          url: "/shop/stripe/checkout",
          primaryButtonLabel: t("Acquista Unlimited"),
          secondaryButtonLabel: t("Cancella"),
          increasesHeaderIndex: true,
          onConfirm: async () => {
            // Do nothing, the stripe component will handle the payment
          },
          hideProgress: true,
          disablePrimaryButton: () => false,
          hideCart: true,
          hideFooter: true,
          showHeaderButton: true,
        },
        {
          url: "/shop/stripe/checkout/succeeded",
          increasesHeaderIndex: false,
          onConfirm: async () => {
            // Do nothing, the stripe component will handle the payment
          },
          primaryButtonLabel: t("Completa l'ordine"),
          secondaryButtonLabel: t("Cancella"),
          hideFooter: true,
        },
      ];
      if (waitingListPriceId && waitingListLength !== undefined) {
        shopStates[0].primaryButtonLabel = t("Prenota il tuo posto");
        shopStates.splice(1, 0, {
          url: "/shop/waiting-list",
          primaryButtonLabel: t("Prenota il tuo posto"),
          increasesHeaderIndex: true,
          hideProgress: true,
          showTotalInCTA: false,
          disablePrimaryButton: () => false,
          hideCart: true,
          hideFooter: false,
          showHeaderButton: true,
          primaryButtonTopText:
            t("⌛️ Prenotando ora saresti al posto: ") + (waitingListLength + 1),
          primaryButtonBottomText: t(
            "10€ adesso, 90€ il primo mese. Poi, 300€ / mese.",
          ),
        });
      }
    }

    if (settings.enableTastingBox && !settings.subsEnabled) {
      shopStates.splice(0, 0, {
        url: "/shop/tasting-box",
        primaryButtonLabel: "disable",
        disablePrimaryButton: () => !me?.shippingInfo.address?.address1,
        increasesHeaderIndex: true,
        hideHeader: true,
        hideFooter: true,
        showTotalInCTA: false,
        editable: true,
        onConfirm: async () => {
          trackUserAction(new TastingBoxSelected());
          void autofillCart();
          navigate(shopStates[2].url);
        },
      });
    }

    if (
      settings.subsEnabled &&
      (!settings.defaultSubscriptionPeriodInWeeks || me.subscriptionProfile)
    ) {
      shopStates.splice(0, 0, {
        url: "/shop/choose-subscription",
        primaryButtonLabel: t("Scegli i piatti"),
        disablePrimaryButton: () => !selectedSub,
        increasesHeaderIndex: true,
        hideHeader: false,
        hideFooter: false,
        hideCart: true,
        editable: false,
        showTotalInCTA: false,
        onConfirm: async () => {
          if (!selectedSub) {
            throw new Error(t("Nessuna subscription selezionata"));
          }
          trackUserAction(
            new SubscriptionProfileSelected({
              subscriptionBoxFrequency: selectedSub.period.count,
              subscriptionBoxSize: selectedSub.boxSize,
            }),
          );
          navigate(shopStates[1].url);
        },
      });
    }

    if (settings.subsEnabled && me.subscriptionProfile) {
      shopStates.push({
        url: "/shop/subscription-recap",
        primaryButtonLabel: "disable",
        increasesHeaderIndex: false,
        hideHeader: true,
        hideFooter: true,
        hideCart: true,
        editable: false,
      });
    }

    if (
      settings.stripePayment &&
      (!settings.subsEnabled ||
        (!me.subscriptionProfile &&
          me.latestOrder &&
          dayjs(me.latestOrder?.expectedShippingDate) > yesterday))
    ) {
      shopStates.push({
        url: "/shop/order-recap",
        primaryButtonLabel: "disable",
        increasesHeaderIndex: false,
        hideHeader: true,
        hideFooter: true,
        hideCart: true,
        editable: false,
      });
    }

    if (
      (settings.membership &&
        !settings.subsEnabled &&
        !me.isMember &&
        !settings.freemiumMode) ||
      (!me.isMember && settings.freemiumMode && !membershipInCart && saving > 0)
    ) {
      const editIndex = shopStates.findIndex((el) => el.url === "/shop/edit");
      shopStates.splice(editIndex + 1, 0, {
        url: "/shop/paywall",
        primaryButtonLabel: t("Vai al pagamento"),
        increasesHeaderIndex: false,
        showTotalInCTA: true,
      });
    }

    if (settings.shopUnderMaintenance) {
      shopStates = [
        {
          url: "/shop/maintenance",
          primaryButtonLabel: t("Ordina"),
          increasesHeaderIndex: false,
          hideProgress: true,
          showTotalInCTA: false,
          disablePrimaryButton: () => true,
          hideCart: true,
          hideFooter: lg,
          hideHeader: lg,
        },
      ];
    }

    return shopStates;
  }, [
    autofillCart,
    currentBox,
    discount?.code,
    getCheckoutUrl,
    getSubscriptionCheckoutUrl,
    lineItems,
    loading,
    me,
    membershipInCart,
    navigate,
    saving,
    selectedSub,
    sendRecentEvents,
    shippingInfoForm,
    totalItems,
    trackUserAction,
    currentStateIndex,
    instalments,
    shippingDate,
    settings,
    t,
    createCheckoutSession,
    shippingNotes,
    lg,
    yesterday,
    waitingListPriceId,
    waitingListLength,
  ]);

  const currentState = shopStates.find(
    (state) => state.url === location.pathname,
  );

  useEffect(() => {
    if (
      shopStates[currentStateIndex].url === "/shop/stripe/checkout/succeeded" ||
      shopStates[currentStateIndex].url === "/shop/stripe/checkout"
    ) {
      return;
    } else if (
      settings.subsEnabled &&
      !selectedSub &&
      !settings.defaultSubscriptionPeriodInWeeks &&
      (!me.latestOrder ||
        dayjs(me.latestOrder?.expectedShippingDate) < yesterday) &&
      shopStates[currentStateIndex].url !== "/shop/choose-subscription"
    ) {
      navigate("/shop/choose-subscription");
    } else if (
      settings.subsEnabled &&
      me.subscriptionProfile &&
      !editingSubscription &&
      shopStates[currentStateIndex].url !== "/shop/subscription-recap"
    ) {
      navigate("/shop/subscription-recap");
    } else if (
      settings.unlimitedEnabled &&
      settings.purchaseLimitation ===
        PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery &&
      me.latestOrder &&
      !me.latestOrder.cancelledAt &&
      dayjs(me.latestOrder.expectedShippingDate) > today
    ) {
      navigate("/unlimited-recap");
    } else if (settings.unlimitedEnabled && me.isWaitingListMember) {
      navigate("/waiting-list-recap");
    }
  }, [
    selectedSub,
    settings.subsEnabled,
    navigate,
    me.subscriptionProfile,
    me.latestOrder,
    me.isWaitingListMember,
    editingSubscription,
    settings.defaultSubscriptionPeriodInWeeks,
    shopStates,
    currentStateIndex,
    yesterday,
    settings.unlimitedEnabled,
    settings.purchaseLimitation,
    today,
  ]);

  useEffect(() => {
    if (currentState) {
      setCurrentStateIndex(shopStates.indexOf(currentState) || 0);
    }
  }, [location, shopStates, currentState]);

  useEffect(() => {
    setHeaderIndexCount(
      shopStates.filter((el) => el.increasesHeaderIndex).length,
    );
  }, [shopStates]);

  useEffect(() => {
    if (location.pathname === "/shop/edit-subscription") {
      setEditingSubscription(true);
      navigate("/shop/choose-subscription");
    }
    if (location.pathname === "/shop/edit-subscription-date") {
      setEditingSubscription(true);
      navigate("/shop/shipping/select-date");
    }
  }, [location, navigate]);

  if (!currentState) {
    // Move to the fist valid state
    navigate(shopStates[0].url);
    return (
      <Spin
        style={{
          color: "#000000",
          width: "100vw",
          height: "20vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }

  const headerIndex = shopStates
    .slice(0, currentStateIndex + 1)
    .filter((el) => el.increasesHeaderIndex).length;

  const eventEmitter = new EventEmitter();

  const primaryButtonFakePromise = {
    subscribe: (f: () => Promise<void>) => {
      eventEmitter.on("cart:primaryButtonClicked", f);
    },
  };

  const secondaryButtonFakePromise = {
    subscribe: (f: () => Promise<void>) => {
      eventEmitter.on("cart:secondaryButtonClicked", f);
    },
  };

  const headerButtonFakePromise = {
    subscribe: (f: () => Promise<void>) => {
      eventEmitter.on("cart:headerButtonClicked", f);
    },
  };

  const cart: Cart = {
    lineItems: lineItems || [],
    addProduct,
    removeProduct,
    bestSellers,
    boxConfig,
    setLineItems,
    currentBox,
    currentState,
    headerIndexCount,
    currentHeaderIndex: headerIndex,
    selectedSub,
    setSelectedSub,
    currentDisplayedBox,
    valid: !!currentBox,
    totalItems: totalItems,
    membershipInCart,
    currentBoxPrice,
    currentTotal,
    editingSubscription,
    setEditingSubscription,
    setMembership: (membership: string | undefined) => {
      setMembershipInCart(membership);
      trackUserAction(
        new MembershipToggleClicked({ setsMembershipInCart: !!membership }),
      );
    },
    discardCart: () => {
      setLineItems([]);
    },
    shippingInfoForm,
    instalments,
    shippingDate,
    shippingTimeSlot,
    setShippingTimeSlot,
    setShippingDate,
    primaryButtonClick: primaryButtonFakePromise,
    secondaryButtonClick: secondaryButtonFakePromise,
    headerButtonClick: headerButtonFakePromise,
    loading,
    setLoading,
    shippingNotes,
    setShippingNotes,
    waitingListPriceId,
    setWaitingListPriceId,
    setDisablePrimaryButton,
    waitingListLength,
    setWaitingListLength,
  };

  const primaryClick = async () => {
    eventEmitter.emit("cart:primaryButtonClicked");
    if (currentState.onConfirm) {
      await currentState.onConfirm();
    } else {
      navigate(shopStates[currentStateIndex + 1].url);
    }
  };

  const primaryButtonDisabled =
    loading ||
    disablePrimaryButton ||
    (currentState.disablePrimaryButton
      ? currentState.disablePrimaryButton()
      : !cart.valid);

  let primaryButtonStyle: CSSProperties = { width: "100%" };
  if (currentStateIndex > 0 && !me.subscriptionProfile && !waitingListPriceId) {
    primaryButtonStyle = { width: "100%", textAlign: "left" };
  }
  const primaryButton = (
    <Row style={{ width: "100%" }} key={"primaryButton"}>
      <Col span={24}>
        {currentState.primaryButtonTopText && (
          <div className={styles.topMicroCopy}>
            {currentState.primaryButtonTopText}
          </div>
        )}
        {/* <Tooltip title={primaryButtonDisabled ? t('Aggiungi dei piatti per completare la Box') : ''}> */}

        <Button
          className={
            styles.button +
            " " +
            styles.primary +
            " " +
            ((!currentState.hideCart && !me.subscriptionProfile) ||
            (lg && !waitingListPriceId)
              ? styles.buttonLeftAligned
              : "")
          }
          disabled={primaryButtonDisabled}
          style={primaryButtonStyle}
          onClick={primaryClick}
        >
          <div>
            {currentState.primaryButtonLabel}
            {me.subscriptionProfile &&
            currentState.url === "/shop/shipping/select-date" ? (
              <img
                alt={"confirm"}
                src={
                  primaryButtonDisabled
                    ? `/icons/check_small_white.svg`
                    : `/icons/check_small_black.svg`
                }
                style={{ marginBottom: -3, marginLeft: 1 }}
              />
            ) : (
              <img
                alt={"next"}
                src={
                  primaryButtonDisabled
                    ? `/icons/next_small_white.svg`
                    : `/icons/next_small_black.svg`
                }
                style={{ marginBottom: -3, marginLeft: 1 }}
              />
            )}
          </div>
          {currentState.showTotalInCTA && (
            <>
              {discount?.amount && (
                <>
                  <span
                    style={{
                      fontWeight: "500",
                      opacity: 0.4,
                      textDecoration: "line-through",
                    }}
                  >
                    {currentTotal.toFixed(2)}€
                  </span>
                  &nbsp;&nbsp;
                </>
              )}
              <span>
                {instalments ? (
                  <div>{instalments.immediatePriceInCents / 100} €</div>
                ) : (
                  `${(currentTotal - (discount?.amount || 0)).toFixed(2)} €`
                )}
              </span>
            </>
          )}
        </Button>
        {currentState.primaryButtonBottomText && (
          <div className={styles.bottomMicroCopy}>
            {currentState.primaryButtonBottomText}
          </div>
        )}

        {/* </Tooltip> */}
      </Col>
    </Row>
  );

  const secondaryClick = async () => {
    eventEmitter.emit("cart:secondaryButtonClicked");
    if (currentState.onSecondaryButtonPress) {
      currentState.onSecondaryButtonPress();
    }
  };

  const headerButtonClick = async () => {
    eventEmitter.emit("cart:headerButtonClicked");
    if (currentState.onHeaderButtonPress) {
      currentState.onHeaderButtonPress();
    }
  };

  const secondaryButton = (
    <Button
      key={"secondary-bottombar"}
      className={styles.button}
      onClick={secondaryClick}
    >
      {currentState?.secondaryButtonLabel}
    </Button>
  );

  const buttons = [];

  const headerButton = (
    <Button
      key={"header-button"}
      className={styles.backButton}
      onClick={headerButtonClick}
      icon={<LeftOutlined />}
    >
      {t("Indietro")}
    </Button>
  );

  if (
    currentState.onSecondaryButtonPress ||
    currentState.secondaryButtonLabel
  ) {
    buttons.push(secondaryButton);
  }
  buttons.push(primaryButton);

  const ShopHeaderLabels = settings.improvedShippingFlow
    ? [t("Scegli i piatti"), t("Indirizzo"), t("Spedizione")]
    : waitingListPriceId
      ? [t("Benvenuto"), t("Come funziona?"), t("Pagamento")]
      : [t("Scegli i piatti"), t("Spedizione"), t("Pagamento")];

  return (
    <CartContext.Provider value={cart}>
      <Layout>
        <Content>
          <div
            style={
              (currentState.url === "/shop/edit" && !settings.subsEnabled) ||
              (currentState.url === "/shop/unlimited-paywall" &&
                waitingListPriceId)
                ? { paddingBottom: 188 }
                : currentState.url === "/shop/subscription-recap" ||
                    (currentState.url === "/shop/stripe/checkout" &&
                      settings.unlimitedEnabled)
                  ? { paddingBottom: 0 }
                  : { paddingBottom: 120 }
            }
          >
            {!currentState.hideHeader &&
              ((settings.subsEnabled || settings.improvedShop) &&
              currentState.url !== "/shop/subscription-recap" ? (
                <ShopHeaderSteps
                  steps={Array.from(
                    { length: headerIndexCount },
                    (value, index) => index + 1,
                  ).map((index) => {
                    return {
                      index: index,
                      active: index === headerIndex,
                    };
                  })}
                  headerButton={headerButton}
                  showHeaderButton={currentState.showHeaderButton}
                />
              ) : (
                <ShopHeader
                  pageTitle={t("Crea la tua Box")}
                  states={ShopHeaderLabels.map((label, index) => {
                    return {
                      title: label,
                      active: index === headerIndex - 1,
                    };
                  })}
                />
              ))}

            {!settings.unlimitedEnabled ? <OfferModal /> : null}
            <OnboardingModal open={onboardingOpen} onOk={dismissOnboarding} />

            <Outlet />

            {!currentState.hideFooter && (
              <CartRecapBottomBar
                open={recapOpen}
                editable={!!currentState?.editable}
                setOpened={setRecapOpen}
                hideCart={currentState.hideCart}
                hideProgress={currentState.hideProgress}
              >
                {loading ? (
                  <Spin
                    style={{
                      color: "#000000",
                      width: "100%",
                      height: "10vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  buttons
                )}
              </CartRecapBottomBar>
            )}
          </div>
        </Content>

        {lg && currentState.showCartSider && (
          <CartRecapSider
            open={recapOpen}
            editable={!!currentState?.editable}
            setOpened={setRecapOpen}
            hideProgress={currentState.hideProgress}
          ></CartRecapSider>
        )}
      </Layout>
    </CartContext.Provider>
  );
};
