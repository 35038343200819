import { Address } from "../AddressModal/Address";
import { Order } from "./order";
import { Dayjs } from "dayjs";
import { OrderType } from "../Networking/usePastOrders";

interface SubscriptionServerLineItem {
  productId: string;
  quantity: number;
}

interface SubscriptionProfileOrder {
  name: string;
  createdAt: Dayjs;
  fulfillmentStatus: OrderFulfillmentStatus;
  expectedShippingDate: Dayjs;
}

interface SubscriptionProfile {
  id: string;
  frequencyInWeeks: number;
  boxSize: 15;
  lastUpdate: string;
  createdAt: string;
  nextDeliveryDate: string;
  validUntil: string;
  enabled: boolean;
  credits: number;
  subscriptionSku: string;
  cartItems: SubscriptionServerLineItem[];
  latestOutboundOrder: SubscriptionProfileOrder;
}

enum MeStatus {
  NO_QUIZ = "NO_QUIZ",
  WAIT_FOR_ORDER = "WAIT_FOR_ORDER",
  ORDER_READY = "ORDER_READY",
  ORDER_SHIPPED = "ORDER_SHIPPED",
}

enum OrderFulfillmentStatus {
  UNFULFILLED = "unfulfilled",
  FULFILLED = "fulfilled",
  PENDING = "pending",
}

interface ShippingInfo {
  address: Address;
  shippingAllowed: boolean;
}

interface InvoiceData {
  firstName?: string;
  lastName?: string;
  businessName?: string;
  province?: string;
  zipCode?: string;
  addressLine?: string;
  taxCode?: string;
  vatNumber?: string;
  pec?: string;
  email?: string;
}

enum CheckoutSessionStatus {
  Complete = "complete",
  Expired = "expired",
  Open = "open",
}

interface CheckoutSession {
  id: string;
  mode: "payment" | "subscription" | "setup";
  status: CheckoutSessionStatus;
  created: Dayjs;
  clientSecret: string;
  errorMessage?: string;
}

enum StripeMembershipStatus {
  Active = "active",
  Paused = "paused",
  Canceled = "canceled",
  Trialing = "trialing",
}

enum WaitingListStatus {
  Waiting = "waiting",
  Purchased = "purchased",
  Refunded = "refunded",
}

interface Recurring {
  interval: string;
  intervalCount: number;
}

interface StripePrice {
  stripeId: string;
  currency: string;
  amount: string;
  product_name?: string;
  recurring: Recurring;
}

interface StripeMembership {
  stripeSubscriptionId: string;
  membershipId: string;
  status: StripeMembershipStatus;
  price: StripePrice;
  validUntil: string;
  scheduledRenewalDate?: string;
  cancelAtPeriodEnd: boolean;
}

interface WaitingList {
  membershipId: string;
  priorityTimestamp: Dayjs;
  status: WaitingListStatus;
  purchasedAt?: Dayjs;
}

interface Me {
  email: string;
  firstName: string;
  lastName: string;
  shippingInfo: ShippingInfo;
  phone: string;
  status: MeStatus;
  order: Order;
  isMember: boolean;
  invoiceData: InvoiceData;
  latestOrder?: OrderType;
  isInternal: boolean;
  shouldCompleteRegistration: boolean;
  shouldRequestContactConsent?: boolean;
  subscriptionProfile: SubscriptionProfile;
  hubspotRecordId?: string;
  pendingCheckoutSession?: CheckoutSession;
  stripeMemberships: StripeMembership[];
  waitingLists: WaitingList[];
  recipesToReview: string[];
}

export type { Me, InvoiceData, SubscriptionProfile };
export {
  MeStatus,
  OrderFulfillmentStatus,
  CheckoutSessionStatus,
  StripeMembershipStatus,
  WaitingListStatus,
};
