import { useMe } from "../AppSetup/MeContext";
import { useSettings } from "../Networking/useSettings";
import dayjs from "dayjs";
import { StripeMembershipStatus } from "../common/me";

export const useUnlimitedMembership = () => {
  const me = useMe();
  const { unlimited } = useSettings();

  const unlimitedMembership = me.stripeMemberships
    .filter(
      (m) =>
        m.membershipId === unlimited?.membershipId &&
        [StripeMembershipStatus.Active, StripeMembershipStatus.Paused].includes(
          m.status,
        ),
    )
    .sort((membership_1, membership_2) =>
      dayjs(membership_2.validUntil).diff(dayjs(membership_1.validUntil)),
    )[0];
  return unlimitedMembership;
};
