import React, { useEffect, useState } from "react";
import useApiClient from "../Networking/useApiClient";
import useCart from "../Hooks/useCart";
import { loadStripe } from "@stripe/stripe-js";
import { Col, notification, Row, Space, Spin } from "antd";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { useMe } from "../AppSetup/MeContext";
import { Header } from "antd/es/layout/layout";
import shopStyles from "../Shop/styles.module.css";
import { useSettings } from "../Networking/useSettings";
import { useNavigate } from "react-router-dom";
import GenericCheckoutRecap from "./GenericCheckoutRecap";
import { CheckoutSessionStatus } from "../common/me";
import {
  OrderCheckout,
  UnlimitedPaymentCheckout,
} from "../Networking/Metrics/UserActions/Shop";
import useUATracker from "../Networking/Metrics/useUATracker";
import { useTranslation } from "react-i18next";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { DelegateCommandStatus } from "../DelegatedCommand/models";
import { useDelegatedCommand } from "../DelegatedCommand/useDelegatedCommands";
import { invalidate } from "../Networking/common";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
);

export const StripeCheckout = () => {
  const {
    unlimitedEnabled,
    unlimited,
    subsEnabled,
    stripePaymentConfigurationId,
  } = useSettings();
  const {
    cancelStripeCheckoutSession,
    createMembershipIntent,
    createWaitingListIntent,
    createCheckoutSession,
  } = useApiClient();
  const { trackUserAction } = useUATracker();
  const {
    lineItems,
    membershipInCart,
    shippingDate,
    shippingTimeSlot,
    shippingNotes,
    valid: cartIsValid,
    headerButtonClick,
    discardCart,
    selectedSub,
    waitingListPriceId,
  } = useCart();
  const me = useMe();
  const navigate = useNavigate();

  const [creatingSession, setCreatingSession] = useState(false);
  const [canceledSession, setCanceledSession] = useState(false);
  const [sentMetrics, setSentMetrics] = useState(false);
  const [traceId, setTraceId] = useState<string | undefined>(undefined);
  const { delegatedCommand } = useDelegatedCommand({ traceId: traceId });

  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    if (sentMetrics) return;
    if (shippingDate) {
      const draftOrderIdentical =
        !!me?.order &&
        me?.order.lineItems.every((li) => {
          const purchasedLi = lineItems.find(
            (el) => el.product.id === li.variantId,
          );
          return purchasedLi && purchasedLi.quantity === li.quantity;
        });
      trackUserAction(
        new OrderCheckout({
          deliveryDate: shippingDate,
          draftOrderExists: !!me?.order,
          draftOrderIdentical: draftOrderIdentical,
          invoiceData: me?.invoiceData,
        }),
      );
      setSentMetrics(true);
    } else if (unlimitedEnabled && !me.isUnlimitedMember) {
      trackUserAction(new UnlimitedPaymentCheckout());
      setSentMetrics(true);
    }
  }, [
    shippingDate,
    me.order,
    me.isUnlimitedMember,
    me.invoiceData,
    lineItems,
    sentMetrics,
    trackUserAction,
    unlimitedEnabled,
  ]);

  useEffect(() => {
    if (me.pendingCheckoutSession) {
      if (me.pendingCheckoutSession.status === CheckoutSessionStatus.Complete) {
        navigate("/shop/stripe/checkout/succeeded");
      }
      setClientSecret(me.pendingCheckoutSession.clientSecret);
    }
  }, [me.pendingCheckoutSession, navigate]);

  useEffect(() => {
    if (!clientSecret) return;

    headerButtonClick.subscribe(async () => {
      setCanceledSession(true);
      cancelStripeCheckoutSession().then(() => {
        if (unlimitedEnabled) {
          navigate("/shop/unlimited-paywall");
        } else {
          navigate("/shop/shipping/select-date");
        }
      });
    });
  }, [
    headerButtonClick,
    cancelStripeCheckoutSession,
    navigate,
    unlimitedEnabled,
    clientSecret,
  ]);

  useEffect(() => {
    if (!traceId) {
      return;
    }
    if (!delegatedCommand) {
      return;
    }
    if (delegatedCommand.status === DelegateCommandStatus.Failed) {
      setTraceId(undefined);
      notification.error({
        message: "Errore",
        description:
          "C'è stato un errore durante la creazione dell'ordine. Per favore contatta l'assistenza.",
      });
      void invalidate("/me");
      navigate("/");
      return;
    }
    const redirectHome = async () => {
      if (delegatedCommand?.status === DelegateCommandStatus.Finished) {
        setTraceId(undefined);
        await invalidate("/me");
        navigate("/");
      }
    };

    void redirectHome();
  }, [traceId, delegatedCommand, navigate, discardCart]);

  useEffect(() => {
    if (unlimitedEnabled && !me.isUnlimitedMember) {
      if (!creatingSession && !me.pendingCheckoutSession && !canceledSession) {
        if (waitingListPriceId) {
          setCreatingSession(true);
          createWaitingListIntent({
            waitingListPriceId: waitingListPriceId,
            paymentConfigurationId: stripePaymentConfigurationId,
          }).then((_) => {
            setCreatingSession(false);
          });
        } else {
          setCreatingSession(true);
          createMembershipIntent({
            subscriptionPriceId: me.latestOrder
              ? unlimited!.afterChurnPrice
              : unlimited!.newUserPrice,
            paymentConfigurationId: stripePaymentConfigurationId,
          }).then((_) => {
            setCreatingSession(false);
          });
        }
      }
      return;
    }

    if (!me.pendingCheckoutSession && !cartIsValid && !traceId) {
      navigate("/shop/edit");
      return;
    }

    if (shippingNotes === undefined) {
      return;
    }

    if (clientSecret) {
      return;
    }

    if (creatingSession) {
      return;
    }

    if (!me.pendingCheckoutSession && !shippingDate) {
      navigate("/shop/shipping/select-date");
      return;
    }

    if (!shippingDate) {
      return;
    }

    setCreatingSession(true);
    createCheckoutSession({
      lineItems,
      shippingInfo: {
        firstName: me.firstName,
        lastName: me.lastName,
        date: shippingDate,
        timeSlot: shippingTimeSlot,
        notes: shippingNotes || "",
      },
      membership: membershipInCart,
      selectedSub,
      paymentConfigurationId: stripePaymentConfigurationId,
    })
      .then((response) => {
        if (response.action === "completed") {
          navigate("/");
        } else if (response.traceId) {
          discardCart();
          setTraceId(response.traceId);
        }
      })
      .catch(() => {
        setCreatingSession(false);
        navigate("/");
      });
  }, [
    lineItems,
    membershipInCart,
    shippingDate,
    shippingNotes,
    createCheckoutSession,
    me,
    navigate,
    cartIsValid,
    selectedSub,
    createMembershipIntent,
    unlimited,
    unlimitedEnabled,
    subsEnabled,
    creatingSession,
    clientSecret,
    canceledSession,
    stripePaymentConfigurationId,
    shippingTimeSlot,
    createWaitingListIntent,
    waitingListPriceId,
    discardCart,
    traceId,
  ]);

  const options = { clientSecret: clientSecret };

  const getElement = () => {
    if (!clientSecret) {
      return (
        <Spin
          style={{
            color: "#000000",
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      );
    }
    return (
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    );
  };

  return getElement();
};

export const StripeBase = () => {
  const { t } = useTranslation();
  const { lg } = useBreakpoint();
  return (
    <Space direction={"vertical"} size={0} style={{ width: "100%" }}>
      <Row justify="center">
        <Header
          style={{
            width: "100%",
            padding: 0,
            zIndex: 1,
            height: 55,
            marginTop: -40,
            backgroundColor: "transparent",
          }}
        >
          <div className={shopStyles.titleCentered} id="title">
            {t("Pagamento")}
          </div>
        </Header>
        <Col span={8} xs={24} md={12} xl={8} style={{ padding: 16 }}>
          <StripeCheckout />
          {lg ? null : <GenericCheckoutRecap />}
        </Col>
        {lg ? (
          <Col span={8} xs={24} md={12} xl={8} style={{ padding: 16 }}>
            <GenericCheckoutRecap />
          </Col>
        ) : null}
      </Row>
    </Space>
  );
};
