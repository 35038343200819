import { Anchor, Button, Card, Col, Row, Spin } from "antd";
import styles from "./styles.module.css";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ProductContext,
  useServerProducts,
} from "../../Networking/useProducts";
import ShowMenu from "./ShowMenu";
import { useMe } from "../../AppSetup/MeContext";
import { PurchaseLimitation, useSettings } from "../../Networking/useSettings";
import useUATracker from "../../Networking/Metrics/useUATracker";
import { UnlimitedPaywallInfoSelected } from "../../Networking/Metrics/UserActions/Shop";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../Networking/useApiClient";
import { WaitingListInfo } from "../../WaitingLists/models";
import useCart from "../../Hooks/useCart";
import { useUnlimitedMembership } from "../../Hooks/useUnlimitedMembership";

const UnlimitedPaywall = () => {
  const { Link } = Anchor;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackUserAction } = useUATracker();
  const { products, isLoading, productsMap } = useServerProducts();
  const { latestOrder } = useMe();
  const { unlimited, purchaseLimitation } = useSettings();
  const { getWaitingList } = useApiClient();
  const { setWaitingListPriceId, setWaitingListLength } = useCart();

  const [waitingListInfo, setWaitingListInfo] = useState<
    WaitingListInfo | undefined
  >(undefined);

  const unlimitedMembership = useUnlimitedMembership();

  const pauseRenewalDate = unlimitedMembership?.scheduledRenewalDate;

  const { lg } = useBreakpoint(true);

  useEffect(() => {
    if (!unlimited || !unlimited.newUserPrice) {
      return;
    }
    getWaitingList({ stripePriceId: unlimited?.newUserPrice }).then(
      (response) => {
        if (response) {
          setWaitingListInfo(response);
          if (!response.availableSeats) {
            setWaitingListPriceId(response.waitingListPriceId);
            setWaitingListLength(response?.queueLength);
          }
        }
      },
    );
  }, [getWaitingList, unlimited, setWaitingListPriceId, setWaitingListLength]);

  const canPurchaseMembershipDirectly = useMemo(
    () => waitingListInfo && waitingListInfo.availableSeats,
    [waitingListInfo],
  );

  if (!products || isLoading) {
    return (
      <Spin
        style={{
          color: "#000000",
          width: "100vw",
          height: "20vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }

  const onMoreInformationClick = () => {
    trackUserAction(new UnlimitedPaywallInfoSelected());
  };

  const explanationInfo = [
    {
      title: t("Paga solo la quota di iscrizione mensile"),
      description: !latestOrder
        ? t("di 300€ / mese. Vuoi solo provare? Il primo mese paghi solo 100€!")
        : t("di 300€ / mese."),
      image: "/images/card-recurring-payment.jpg",
    },
    {
      title:
        purchaseLimitation ===
        PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery
          ? t("Tutti i piatti di cui hai bisogno")
          : t("Quanti piatti vuoi, quando vuoi"),
      description:
        purchaseLimitation ===
        PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery
          ? t(
              "Ordina in base alle tue necessità, in box da 15 piatti e senza costi aggiuntivi. Puoi effettuare il prossimo ordine non appena ricevi la box precedente.",
            )
          : t(
              "Ordina in base alle tue necessità, in box da 15 piatti e senza costi aggiuntivi.",
            ),
      image: "/images/card-unlimited-dishes.jpg",
    },
    {
      title: t("Interrompi o cancella l'abbonamento quando vuoi"),
      description: t(
        "Puoi disdire in qualsiasi momento, senza vincoli o costi.",
      ),
      image: "/images/card-cancel.jpg",
    },
    {
      title: t("Nuovi piatti ogni mese"),
      description: t(
        "Il nostro menù usa ingredienti freschi e stagionali, gusta novità diverse ogni mese!",
      ),
      image: "/images/card-dishes.jpg",
    },
  ];

  const renderOfferForSmallScreen = () => {
    return (
      <Row justify="center">
        <Col span={8} xs={24} md={12} className={styles.column}>
          {!latestOrder && (
            <div className={styles.smallBanner}>
              {t("Sconto di 200€ sul primo mese!")}
            </div>
          )}
          {pauseRenewalDate && (
            <div className={styles.smallBanner}>
              <Trans
                i18nKey={"pauseBanner"}
                pauseRenewalDate={pauseRenewalDate}
              >
                La pausa finirà il {{ pauseRenewalDate }} e l'abbonamento verrà
                rinnovato.
              </Trans>
            </div>
          )}
          <div className={styles.fullWidthBanner}>
            <video
              className={styles.unlimitedVideo}
              autoPlay
              loop
              muted
              controls
              playsInline
            >
              <source src={"/videos/unlimited_dishes.mp4"} type="video/mp4" />
            </video>
            <h1 className={styles.title}>
              {t("Acquista l'abbonamento")}
              <span className={styles.highlightYellow}>Unlimited</span>
            </h1>
            <Row
              justify="center"
              style={{ marginBottom: 24, fontSize: "20px" }}
            >
              {!latestOrder ? (
                <>
                  <span
                    style={{
                      textDecoration: "line-through",
                      opacity: 0.5,
                      marginRight: "8px",
                    }}
                  >
                    300€
                  </span>
                  <br />
                  <span style={{ fontWeight: "bold" }}>100€ / {t("mese")}</span>
                </>
              ) : (
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("300€ / mese")}
                </span>
              )}
            </Row>
            <div
              style={{
                display: "flex",
                gap: 16,
                marginBottom: 24,
                justifyContent: "center",
              }}
            >
              {!latestOrder && (
                <div className={styles.infoDetail}>
                  <img
                    src={"/icons/discount_blue.svg"}
                    alt={"discount tag icon"}
                  />
                  {t("Primo mese scontato")}
                </div>
              )}
              <div className={styles.infoDetail}>
                <img src={"/icons/infinite.svg"} alt={"infinite icon"} />
                {t("Ordina piatti illimitati")}*
              </div>
              <div className={styles.infoDetail}>
                <img src={"/icons/cancel_blue.svg"} alt={"cancel icon"} />
                {t("Disdici in ogni momento")}
              </div>
            </div>
            <small>
              *
              {t(
                "Si applicano le limitazioni previste dai termini di servizio.",
              )}
            </small>
            <Link
              href="#explanation"
              title={
                <Row justify="center">
                  <b
                    className={styles.smallBtn}
                    onClick={onMoreInformationClick}
                  >
                    {t("Come funziona?")}
                    <img
                      src="/icons/down_small_green.svg"
                      alt="scroll"
                      className={styles.smallIcon}
                    />
                  </b>
                </Row>
              }
            />
          </div>
        </Col>
      </Row>
    );
  };

  const renderOfferForBigScreen = () => {
    return (
      <Row justify="center" className={styles.unlimitedBannerBigScreen}>
        <Col span={8} lg={24} xxl={20} className={styles.column}>
          <div className={styles.fullWidthBanner}>
            <div className={styles.divInImage}>
              <h1 className={styles.title}>
                {t("Acquista l'abbonamento")}
                <br />
                <span className={styles.highlightYellow}>Unlimited</span>
              </h1>
              <Row justify="center" style={{ marginBottom: 24, fontSize: 26 }}>
                {!latestOrder ? (
                  <>
                    <span
                      style={{
                        textDecoration: "line-through",
                        opacity: 0.5,
                        marginRight: "8px",
                      }}
                    >
                      300€
                    </span>
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      100€ / {t("mese")}
                    </span>
                  </>
                ) : (
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {t("300€ / mese")}
                  </span>
                )}
              </Row>
              <div
                style={{
                  display: "flex",
                  gap: 16,
                  marginBottom: 40,
                  justifyContent: "center",
                }}
              >
                {!latestOrder && (
                  <div className={styles.infoDetail}>
                    <img
                      src={"/icons/discount_blue.svg"}
                      alt={"discount tag icon"}
                    />
                    {t("Primo mese scontato")}
                  </div>
                )}
                <div className={styles.infoDetail}>
                  <img src={"/icons/infinite.svg"} alt={"infinite icon"} />
                  {t("Ordina piatti illimitati")}*
                </div>
                <div className={styles.infoDetail}>
                  <img src={"/icons/cancel_blue.svg"} alt={"cancel icon"} />
                  {t("Disdici in ogni momento")}
                </div>
              </div>
              {canPurchaseMembershipDirectly ? (
                <Button
                  className={styles.button + " " + styles.green}
                  onClick={() => navigate("/shop/stripe/checkout")}
                >
                  {t("Acquista Unlimited")}
                </Button>
              ) : (
                <>
                  <div className={styles.microCopy}>
                    {t(
                      "A causa dell’elevata domanda, per garantirvi un migliore servizio, Humamy è temporaneamente ad accesso limitato. 🙏",
                    )}
                  </div>
                  <Button
                    className={styles.button + " " + styles.green}
                    onClick={() => navigate("/shop/waiting-list")}
                  >
                    {t("Prenota il tuo posto")}
                  </Button>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  gap: 16,
                  marginBottom: 40,
                  justifyContent: "center",
                }}
              >
                *
                {t(
                  "Si applicano le limitazioni previste dai termini di servizio.",
                )}
              </div>
              <Link
                href="#menu"
                title={
                  <Row justify="center" style={{ marginTop: 8 }}>
                    <b className={styles.smallBtn}>
                      {t("Guarda il menu")}
                      <img
                        src="/icons/down_small_green.svg"
                        alt="scroll"
                        className={styles.smallIcon}
                      />
                    </b>
                  </Row>
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderExplanationForSmallScreen = () => {
    return (
      <Row justify="center">
        <Col span={8} xs={24} md={12} lg={24}>
          <div
            className={styles.fullWidthBanner}
            style={{ margin: 0, textAlign: "left" }}
            id="explanation"
          >
            <img
              className={styles.modalImage}
              src={"/images/generic-dishes.avif"}
              alt={"delicious dishes"}
            />
            <h1
              className={styles.title}
              style={{ fontSize: 22, marginBottom: 24 }}
            >
              {t("Come funziona Humamy Unlimited?")}
            </h1>

            {explanationInfo.map((card, index) => (
              <div className={styles.listItem} key={index}>
                <img
                  src={"/icons/check_small_blue.svg"}
                  alt={"check"}
                  style={{ marginTop: 2 }}
                />
                <div>
                  <b>{card.title}</b>
                  <br />
                  <span>{card.description}</span>
                </div>
              </div>
            ))}

            <Link
              href="#menu"
              title={
                <Row justify="center">
                  <b className={styles.smallBtn}>
                    {t("Guarda il menu")}
                    <img
                      src="/icons/down_small_green.svg"
                      alt="scroll up"
                      className={styles.smallIcon}
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </b>
                </Row>
              }
            />
          </div>
        </Col>
      </Row>
    );
  };

  const renderExplanationForBigScreen = () => {
    return (
      <>
        <Row
          justify="center"
          // gutter={40}
          className={styles.rowMargin}
        >
          <h1 id="explanation" className={styles.titleInfo}>
            {t("Come funziona Humamy Unlimited?")}
          </h1>
        </Row>

        <Row justify="center" gutter={40} className={styles.rowMargin}>
          {explanationInfo.map((card, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6} xxl={4}>
              <Card
                cover={
                  <img
                    alt={`Cover for ${card.title}`}
                    src={card.image}
                    style={{
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: 0,
                    }}
                  />
                }
                bordered={false}
                className={styles.infoCard}
              >
                <Card.Meta
                  title={<span className={styles.cardTitle}>{card.title}</span>}
                  description={card.description}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <Row justify="center" style={{ margin: 56 }}>
          {canPurchaseMembershipDirectly ? (
            <Button
              className={styles.button + " " + styles.yellow}
              onClick={() => navigate("/shop/stripe/checkout")}
            >
              {t("Acquista Unlimited")}
            </Button>
          ) : (
            <Col span={8} xl={6}>
              <div
                className={styles.microCopy}
                style={{ width: "100%", marginTop: 24 }}
              >
                {t(
                  "A causa dell’elevata domanda, per garantirvi un migliore servizio, Humamy è temporaneamente ad accesso limitato. 🙏",
                )}
              </div>
              <Button
                className={styles.button + " " + styles.yellow}
                style={{ width: "100%" }}
                onClick={() => navigate("/shop/waiting-list")}
              >
                {t("Prenota il tuo posto")}
              </Button>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const productsContext = { products, productsMap };

  return (
    <>
      <div style={lg ? { marginBottom: -100 } : { marginBottom: -40 }}>
        {lg ? renderOfferForBigScreen() : renderOfferForSmallScreen()}
        <ProductContext.Provider value={productsContext}>
          <ShowMenu />
        </ProductContext.Provider>
        {lg
          ? renderExplanationForBigScreen()
          : renderExplanationForSmallScreen()}
      </div>
    </>
  );
};

export default UnlimitedPaywall;
